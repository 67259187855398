import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorMessage, useFormik } from "formik";
import * as yup from "yup";
// import axios from "axios";
import Alert from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { handelUserLogout } from "../../../redux/actions";
import HeaderMenu from "../../../components/HeaderMenu/headerMenu";
import SideMenu from "../../../components/SideMenu/sideMenu";
import PhoneNumberInput from "../../../components/phoneNumber/phoneNumber";
// import brand from "../../../assets/images/brand-logo.png";
import smsIcon from "../../../assets/images/sms-icon.png";
import emailIcon from "../../../assets/images/email-icon.png";
import { deleteSeekerProfile as deleteSeekerProfileApi, 
  deletePosterProfile as deletePosterProfileApi } from "../../../api/users";

import {
  API_CHANGEPHONE_OTP,
  postApiPoster,
  postApi,
  API_CURRENT_USER,
  API_UPDATE_SEEKER_PROFILE,
  API_UPDATE_USER_PROFILE,
  // POSTER_DELETE_JOB,
} from "../../../utils";

const validationSchema = yup.object({
  fullName: yup.string("Enter Full Name").required("Full Name is required"),
  location: yup.string("Enter Location").required("Location is required"),
});

function ProfileUpdate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.UserAuth?.userData?.payload?.user?._id || null);
  const userToken = useSelector((state) => state.UserAuth?.userData?.payload?.token || null);
  const userType = useSelector((state) => state.userType?.userType || null);

  const [userDetail, setUserDetails] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [smsType, setSmsType] = useState("SMS");
  const [sendOtp, setSendOtp] = useState(0);
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [displayProperty, setDisplayProperty] = useState("");

  const toast_error_config = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  }

  useEffect(() => {
    requestDataFromServer();
  }, []);

  const requestDataFromServer = () => {
    setLoading(true);
    const obj = {
      usertype: userType.payload === "jobPoster" ? 2 : 1,
      _id: userId,
    };
    postApi(API_CURRENT_USER, obj, userToken, successFunction, errorCallBack);
  };

  const successFunction = (res) => {
    const data = res.data.data;
    setUserDetails(data);
    setEmail(data.email);
    setCompanyName(data.companyName);
    setContactNumber(data.contactNumber);
  };

  const getInputValuesEmail = (e) => {
    const { name, value } = e.target;
    if (!value) {
      return toast.error(`Please enter ${name}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    if ((name === "email" && value !== userDetail.email) || (name === "mobileNumber" && value !== userDetail.contactNumber)) {
      setSendOtp(1);
      setEmail(value);
    } else {
      setSendOtp(0);
      setEmail(userDetail?.email);
    }
  };

  const getInputValuesCompany = (e) => {
    const { name, value } = e.target;
    if (!value) {
      return toast.error(`Please enter ${name}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    setCompanyName(value);
  };

  const getContactNumber = (number) => {
    if (!number) {
      return toast.error("Please enter Mobile No.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    setSendOtp(number !== userDetail.contactNumber ? 1 : 0);
    setContactNumber(number);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: userDetail.fullName || "",
      location: userDetail.location || "",
    },
    validationSchema,
    onSubmit: (values) => {
      values._id = userId;
      if (!values.fullName) {
        return toast.error("Please enter full name", toast_error_config);
      }
      if (!values.location) {
        return toast.error("Please enter location", toast_error_config);
      }
      if (userType.payload === "jobPoster" && !companyName) {
        return toast.error("Please enter company name", toast_error_config);
      }

      // if (sendOtp === 1) {
      //   const obj = {
      //     email: email || userDetail.email,
      //     contactNumber: contactNumber || userDetail.contactNumber,
      //     type: smsType,
      //     userId,
      //     usertype: userType.payload,
      //   };
      //   postApiPoster(API_CHANGEPHONE_OTP, obj, userToken, successCallBack, errorCallBack);
      // }

      const api = userType.payload === "jobPoster" ? API_UPDATE_USER_PROFILE : API_UPDATE_SEEKER_PROFILE;
      postApiPoster(api, values, userToken, successUpdateFunction, errorCallBack);
    },
  });

  const successUpdateFunction = (res) => {
    toast.success("Details Updated");
  };

  const successCallBack = (response) => {
    setLoading(false);
    toast.success(response.data.message, {
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    navigate("../verifyInfo", { state: response.data.data });
  };

  const errorCallBack = (error) => {
    setLoading(false);
    toast.error(error.response.data.error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleLogout = () => {
    navigate("/");
    dispatch(handelUserLogout());
    
  };

  const onDeleteProfileClick = () => {
    setDisplayProperty("d-none");
    Alert.fire({
      title: "Are you sure?",
      text: "You want to delete your account",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteProfile();
      }
      setDisplayProperty("");
    });
  };

  const deleteProfile = () => {
    const type = userType.payload
    if(type === 'jobSeeker'){
      deleteSeeker()
    }else{
      deletePoster()
    }
  };

  function deleteSeeker(){
    deleteSeekerProfileApi(userId).then((_resp) => {
      if(_resp.data.result === 'success'){
        handleLogout()
      } else {
          console.log(_resp.data);
      }
    }).catch((_resp) => {
        console.error(_resp);
    });
  }

  function deletePoster(){
    deletePosterProfileApi(userId).then((_resp) => {
      if(_resp.data.result === 'success'){
        handleLogout()
      }else{
        console.log(_resp.data)
      }
    }).catch((_resp) => {
      console.error(_resp)
    })
  }

  return (
    <>
      <HeaderMenu />
      <section id="dashboard-cnt-area">
        <div className="container">
          <div className="row dashboard-cnt-row">
            {userType.payload !== "jobPoster" && <SideMenu />}
            {userType.payload === "jobPoster" && <div className="col-lg-3 col-sm-4 col-xs-12"></div>}
            <div className="col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt mb-3">
              <div className="row my-3">
                <div className="col-lg-12 recent-page-heading-jp mb-4">
                  <a style={{ background: "#d9453d" }}>Update Profile</a>
                  <a className="mx-5" style={{ background: "#d9453d" }} onClick={onDeleteProfileClick}>
                    Delete Profile
                  </a>
                </div>
              </div>
              <div className="row mt-5 pb-5">
                <div className="col-lg-10 col-md-10 col-xs-10 col-12">
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-lg-6 col-sm-6 postion-relative">
                        <div className="signup-input-bx">
                          <label className={displayProperty}>Full Name</label>
                          <input id="fullName" name="fullName" type="text"
                            placeholder="Enter your full name" value={formik.values.fullName}
                            onChange={formik.handleChange} error={formik.touched.fullName && Boolean(formik.errors.fullName)}/>
                          {formik.errors.fullName && formik.touched.fullName && (
                            <p style={{ color: "red" }}>{formik.errors.fullName}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6 postion-relative">
                        <div className="signup-input-bx">
                          <label className={displayProperty}>Location</label>
                          <input type="text" name="location" placeholder="Enter your location"
                            value={formik.values.location} onChange={formik.handleChange} error={formik.touched.location && Boolean(formik.errors.location)} />
                          {formik.errors.location && formik.touched.location && (
                            <p style={{ color: "red" }}>{formik.errors.location}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 postion-relative">
                        <div className="signup-input-bx">
                          <label className={displayProperty}>Email Address</label>
                          <input type="email" name="email" placeholder="Enter email address" value={email} onChange={getInputValuesEmail}/>
                        </div>
                      </div>
                      {userType.payload === "jobPoster" && (
                        <div className="col-lg-6 col-sm-6 postion-relative">
                          <div className="signup-input-bx">
                            <label className={displayProperty}>Company Name</label>
                            <input type="text" name="companyName" value={companyName} onChange={getInputValuesCompany}/>
                          </div>
                        </div>
                      )}
                      <div className="col-lg-6 col-sm-6 postion-relative">
                        <div className="signup-input-bx">
                          <label className={displayProperty}>Mobile Number</label>
                          <PhoneNumberInput
                            onTouch={getContactNumber}
                            initialValue={userDetail.contactNumber}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="col-lg-12 signup-verification-col" style={{ padding: "0 15px" }}>
                      {sendOtp === 1 && (
                        <>
                          <p>How would you like to receive your verification code?</p>
                          <div className="wrapper">
                            <input type="radio" name="select" id="option-1" defaultChecked onClick={() => setSmsType("SMS")} />
                            <input type="radio" name="select" id="option-2" onClick={() => setSmsType("EMAIL")}/>
                            <label htmlFor="option-1" className="option option-1">
                              <div className="dot"></div>
                              <div>
                                <h6><img src={smsIcon} alt="SMS Icon" /> SMS</h6>
                                <p>You will receive a code via SMS.</p>
                              </div>
                            </label>
                            <label htmlFor="option-2" className="option option-2">
                              <div className="dot"></div>
                              <div>
                                <h6><img src={emailIcon} alt="Email Icon" /> Email</h6>
                                <p>You will receive a code via Email.</p>
                              </div>
                            </label>
                          </div>
                        </>
                      )}
                      <br />
                      <br />
                      <button type="submit" className="form-btn">Update</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProfileUpdate;