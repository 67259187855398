import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';

import EagleMan from '../../../assets/images/eagle-man-img.png';
import ProfileIcon from '../../../assets/images/profile-img-standard.png';
import { GetProfileData as GetProfileDataApi } from '../../../api/users';
import { postApi } from '../../../utils';
import { API_UPDATE_INTEREST, API_PERSONAL_BUYPROFILE } from '../../../utils';
import HeaderMenu from '../../../components/HeaderMenu/headerMenu';
import SideMenu from '../../../components/SideMenu/sideMenu';

export default function MatchesFound() {
  const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);
  const userType = useSelector((state) => state.userType.userType);
  const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
  const { state } = useLocation();
  console.log('state: ', state)
  const navigate = useNavigate();

  const [pageMounted, setPageMounted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [userProfileData, setUserProfileData] = useState(null);
  const [openVideo, setOpenVideo] = useState(false);

  const shortListed = state.selectedProfile === 'Short Listed';
  const percent = state?.percentage?.toFixed(0);
  const circleColour = percent >= 60 ? '#1ec991' : percent >= 50 ? '#5e43f8' : '#d9453d';

  const posterInterested = state?.posterInterested;
  const jobInfo = state?.info;
  const agentName = userProfileData?.fullName;
  const companyDes = jobInfo?.company?.[0]?.description || 'A business description is most relevant when starting a company but is often effective when maintained regularly as a business grows.';
  const image = userProfileData?.imagePath ?? userProfileData?.image ?? ProfileIcon;
  const video = userProfileData?.videoPath;
  const jobLocation = jobInfo?.locationName ?? '';
  const companySize = jobInfo?.company?.[0]?.size;
  const jobDes = jobInfo?.jobDesc || 'Informative job titles are more effective than general ones, so be precise by including key phrases that accurately describe the role.';
  const infoCompanyDesc = jobInfo?.companyDesc || 'A business description is most relevant when starting a company but is often effective when maintained regularly as a business grows.';
  const bio = userProfileData?.bio;
  const employmentType = jobInfo?.employmentMode;
  const salary = jobInfo?.employmentType.replace(/:/g, ' : ');
  const startDate = jobInfo?.startDate;
  const jobTitle = jobInfo?.jobTitle?.option;
  const companyName = userProfileData?.companyName ?? jobTitle;

  const styles = {
    btnStyle: {
      transform: 'scale(0.8)',
      marginTop: '-1%',
    },
  };

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
  }, []);

  useEffect(() => {
    if (!pageMounted) {
      setPageMounted(true);
      getUserFullData();
    }
  }, [pageMounted]);

  const matchData = state.jobDetails;
  console.log('matchData: ', matchData)

  // let hardSkillsData = null;
  // let hardSkillsPercentage = null;
  // let hardSkillsPercentageColor = null;
  // let softSkillsData = null;
  // let softSkillsPercentage = null;
  // let softSkillsPercentageColor = null;

  // if (matchData) {
  //   hardSkillsData = matchData.hardSkillsResult;
  //   hardSkillsPercentage = hardSkillsData.finalMatchPercentage.toFixed(0);
  //   hardSkillsPercentageColor = hardSkillsPercentage >= 60 ? '#1ec991' : hardSkillsPercentage >= 40 ? '#ffdb59' : '#d9453d';

  //   softSkillsData = matchData.softSkillsResult;
  //   softSkillsPercentage = softSkillsData.finalMatchPercentage.toFixed(0);
  //   softSkillsPercentageColor = softSkillsPercentage >= 60 ? '#1ec991' : softSkillsPercentage >= 40 ? '#ffdb59' : '#d9453d';
  // }

  function getUserFullData() {
    const type = userType.payload === 'jobSeeker' ? 1 : 2;
    GetProfileDataApi(userId, type)
      .then((_resp) => {
        const data = _resp.data.data;
        if ('_id' in data) {
          setUserProfileData(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const UpdateInterst = (value) => {
    const obj = {
      type: userType.payload === 'jobSeeker' ? 1 : 2,
      seekerId: state.seekerId,
      posterId: state.posterId,
      jobId: state.jobId,
      status: value,
    };

    if (value === 1) {
      postApi(API_UPDATE_INTEREST, obj, userToken, () => navigate(-1), errorFunction);
    } else {
      navigate('../feedback', { state: obj });
    }
  };

  const BuyProfile = () => {
    const obj = {
      seekerId: state.seekerId,
      posterId: state.posterId,
      jobId: state.jobId,
      buyProfile: 1,
    };

    Swal.fire({
      title: 'To purchase this profile. You will be charged 1 credit from your credit wallet',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        postApi(API_PERSONAL_BUYPROFILE, obj, userToken, () => navigate('/mainMenu/' + state.jobId), errorFunction);
      }
    });
  };

  const errorFunction = (err) => {
    toast.error(err.response.data.message, {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleChat = () => {
    navigate('../chat', { state: state });
  };

  const getSkillProgress = (skill) => {
    if (skill.qualify) {
      const matchPercentage = ((skill.matchTotal) * 100).toFixed(0);
      const color = matchPercentage >= 60 ? '#1ec991' : matchPercentage >= 40 ? '#ffdb59' : '#d9453d';
      return (
        <div style={{ width: '100%', height: '20px', border: '1px solid #b8b8b8' }}>
          <div style={{ width: `${matchPercentage}%`, height: '18px', background: color }}></div>
        </div>
      );
    }
    return null;
  };

  const getPercentageImage = (_percentage) => {
    if (_percentage < 0 || _percentage > 100) {
      return 'Invalid percentage';
    } else if (_percentage >= 0 && _percentage < 20) {
      return { image: 'Very Low' };
    } else if (_percentage >= 50 && _percentage < 55) {
      return {
        image: 'EGG',
        text: <span>Incubating</span>,
        color: '#42d9ff',
        name: (
          <span>
            SUNNY-SIDE <span style={{ color: '#42d9ff' }}>EGG</span>
          </span>
        ),
      };
    } else if (_percentage >= 56 && _percentage < 60) {
      return {
        image: 'sparrow',
        text: <span>Strong Match</span>,
        color: '#ff3300',
        name: (
          <span>
            HOUSE <span style={{ color: '#ff3300' }}>SPARROW</span>
          </span>
        ),
      };
    } else if (_percentage >= 61 && _percentage < 70) {
      return {
        image: 'CHICKEN',
        text: <span>Good Match</span>,
        color: '#ffcc80',
        name: (
          <span>
            FIGHTING <span style={{ color: '#ffcc80' }}>ROOSTER</span>
          </span>
        ),
      };
    } else if (_percentage >= 71 && _percentage < 79) {
      return {
        image: 'CROW',
        text: <span>Great Match</span>,
        color: '#42d9ff',
        name: (
          <span>
            CUNNING <span style={{ color: '#42d9ff' }}>CROW</span>
          </span>
        ),
      };
    } else if (_percentage >= 80 && _percentage < 89) {
      return {
        image: 'PENGUIN',
        text: <span>Excellent Match</span>,
        color: '#3366ff',
        name: (
          <span>
            PENGUIN <span style={{ color: '#3366ff' }}>KING</span>
          </span>
        ),
      };
    } else if (_percentage >= 90 && _percentage <= 100) {
      return {
        image: 'eagle',
        text: <span>Top Rated Match</span>,
        color: '#cc0000',
        name: (
          <span>
            MIGHTY <span style={{ color: '#cc0000' }}>EAGLE</span>
          </span>
        ),
      };
    }
  };

  const createMatchCardComp = () => {
    const config = getPercentageImage(percent);
    return (
      <div className="d-flex justify-content-between">
        <div className="text-end">
          <h5 className="text-end">{config.name}</h5>
          <div className="text-center">
            <b className="float-end p-1" style={{ backgroundColor: config.color }}>
              {config.text}
            </b>
          </div>
        </div>
        <div>
          <img src={`/progress-images/${config.image}.png`} className="img-fluid" style={{ borderRadius: '15px', height: '150px', width: '150px' }} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <HeaderMenu />
      {userProfileData !== null ? (
        <>
          <section id="dashboard-cnt-area">
            <div className="container">
              <div className="row dashboard-cnt-row dashboard-sidebar">
                {!isMobile && <SideMenu />}
                <div className="col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt">
                  <div className="row">
                    <div className="col-lg-12 mt-4 mb-3">
                      <p style={{ fontFamily: "'Space Mono', monospace", fontSize: '1.4em' }}>
                        <span style={{ color: '#1ec991' }}>Suggested for</span>
                        &nbsp;<b className="capitalize"> {state?.jobDetails?.jobTitle?.option} &ndash; {state.jobDetails.locationName}</b>{' '}
                      </p>
                    </div>
                    <div className="col-lg-12">
                      <div className="match-card">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex justify-content-between">
                            <div className="match-card-image-container">
                              <img src={image} className='img-fluid' style={{maxHeight: '200px'}} alt="Profile" id='profile-image' />
                              {/* {video && (
                                <div onClick={() => setOpenVideo(!openVideo)} className="video-profile">
                                  <i className="fa fa-play text-light" style={{ paddingLeft: '2px' }} />
                                </div>
                              )} */}
                            </div>
                            <div className="d-flex align-items-center ms-2">
                              {/* TODO check this logic */}
                              <div className="match-name">
                                {shortListed ? agentName : agentName?.split(' ')?.[0]}
                                {shortListed && (
                                  <>
                                    &nbsp;&ndash;&nbsp;<span className="capitalize">{companyName}</span>
                                  </>
                                )}
                              </div>
                              <div>
                                <span className='ms-2 me-2'>-</span>
                                {jobLocation}</div>
                            </div>
                          </div>
                          <div>
                            {userProfileData?.usertype === 2 ? (
                              createMatchCardComp()
                            ) : (
                              <div className="match-card-percent">
                                <div style={{ fontSize: '0.8em' }}>Overall</div>
                                <div className="match-card-percent-container" style={{ background: circleColour }}>
                                  {percent}%
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <p className="mt-3" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                        <span style={{ color: '#1ec991' }}>Status</span>&nbsp;&nbsp;{state.selectedProfile}
                      </p>
                    </div>
                    {shortListed && (
                      <div className="col-lg-12 mt-4">
                        <h5>Company Pitch</h5>
                        <p>{companyDes || bio || infoCompanyDesc}</p>
                      </div>
                    )}
                    <div className="col-lg-12 mt-4">
                      <h5>Job description</h5>
                      <p>{jobDes}</p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                      <h5>Company Size</h5>
                      <p>{companySize}</p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                      <h5>Position Type</h5>
                      <p className="capitalize">{employmentType}</p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                      <h5>Maximum Salary</h5>
                      <p className="capitalize">{salary}</p>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                      <h5>Start date</h5>
                      <p>{moment(startDate).format('YYYY/MM/DD')}</p>
                    </div>
                    <div className="col-lg-6">
                      <div className="skills-info-card">
                        <div className="skills">
                          <p style={{ fontWeight: 'bold' }}>Job Specific Skills</p>
                          <ul className='text-start'>
                            {matchData['hardSkill'].map((obj, index) => 
                              <li key={index}>{obj['skill']['option']}</li>
                            )}
                          </ul>
                          {/* <div className="skills-container container">
                            {hardSkillsData?.skills.map((skill) => (
                              <div className="row" key={skill.name}>
                                <div className="col-md-4">
                                  <div className="skill-name">{skill.name}</div>
                                </div>
                                <div className="col-md-8">
                                  <div className="skill-progress">{getSkillProgress(skill)}</div>
                                </div>
                              </div>
                            ))}
                          </div> */}
                        </div>
                        {/* <div className="match-percentage">
                          <p style={{ fontWeight: 'bold' }}>Match</p>
                          <div className="match-card-percent-container" style={{ background: hardSkillsPercentageColor }}>
                            {hardSkillsPercentage}%
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="skills-info-card">
                        <div className="skills">
                          <p style={{ fontWeight: 'bold' }}>People Skills</p>
                          <ul className='text-start'>
                            {matchData['softSkill'].map((obj, index) => 
                              <li key={index}>{obj['skill']['option']}</li>
                            )}
                          </ul>
                          {/* <div className="skills-container container">
                            {softSkillsData?.skills.map((skill) => (
                              <div className="row" key={skill.name}>
                                <div className="col-md-4">
                                  <div className="skill-name">{skill.name}</div>
                                </div>
                                <div className="col-md-8">
                                  <div className="skill-progress">{getSkillProgress(skill)}</div>
                                </div>
                              </div>
                            ))}
                          </div> */}
                        </div>
                        {/* <div className="match-percentage">
                          <p style={{ fontWeight: 'bold' }}>Match</p>
                          <div className="match-card-percent-container" style={{ background: softSkillsPercentageColor }}>
                            {softSkillsPercentage}%
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {state?.posterInterested === 1 && state?.seekerInterested === 1 && state?.info.userType === 1 && !state?.buyProfile ? (
                      <div className="col-sm-12 mt-4 like-deslike-col">
                        <button onClick={BuyProfile} className="matching-button" style={{ background: '#1ec991', width: '300px', fontSize: '20px', ...styles.btnStyle }}>
                          Add to Talent List
                        </button>
                      </div>
                    ) : state?.posterInterested === 1 && state?.seekerInterested === 1 && state?.info.userType === 1 && state?.buyProfile ? (
                      // CORRECT LOGIC "remove the userType"
                      <>
                      {/* TODO leave like this until until someone says otherwise */}
                        <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                          <h5>Seeker Name</h5>
                          <p>{userProfileData.fullName}</p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                          <h5>Email </h5>
                          <p>{userProfileData.email}</p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                          <h5>Contact Number</h5>
                          <p>{userProfileData.contactNumber}</p>
                        </div>
                        <div className="message-btn msg-btn text-right">
                          <button
                            className="matching-button"
                            style={{ background: '#f45950', fontSize: '18px', ...styles.btnStyle }}
                            onClick={() => window.location.href = userProfileData?.document ?? ''}
                            download
                          >
                            Download CV
                          </button>
                          <button onClick={handleChat} className="matching-button" style={{ background: '#1ec991', fontSize: '18px', ...styles.btnStyle }}>
                            Message
                          </button>
                        </div>
                      </>
                    ) : state?.posterInterested === 1 && state?.seekerInterested === 1 && state?.info.userType === 2 && !state?.buyProfile ? null : state?.posterInterested === 1 && state?.seekerInterested === 1 && state?.info.userType === 2 && state?.buyProfile ? (
                      <>
                        {/* <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                          <h5>Company Name</h5>
                          <p>{userProfileData.companyName}</p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                          <h5>Contact Number</h5>
                          <p>{userProfileData.contactNumber}</p>
                        </div>
                        <div className={`message-btn text-right ${isMobile ? 'd-flex justify-content-center' : 'd-flex justify-content-end'}`}>
                          <button onClick={handleChat} className="matching-button" style={{ background: '#1ec991', fontSize: '18px', width: '200px', ...styles.btnStyle }}>
                            Message
                          </button>
                        </div> */}
                      </>
                    ) : (
                      null
                    )}
                    {state?.buyProfile === true && state?.isDislike === false?
                      <>
                        {/* TODO only show if in [SL] */}
                        <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                          <h5>Company Name</h5>
                          <p>{userProfileData.companyName}</p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                          <h5>Contact Number</h5>
                          <p>{userProfileData.contactNumber}</p>
                        </div>
                        <div className={`message-btn text-right ${isMobile ? 'd-flex justify-content-center' : 'd-flex justify-content-end'}`}>
                          <button onClick={handleChat} className="matching-button" style={{ background: '#1ec991', fontSize: '18px', width: '200px', ...styles.btnStyle }}>
                            Message
                          </button>
                        </div>
                      </>
                    : null}
                    { state?.posterInterested !== state?.seekerInterested ?
                      <>
                      {/* TODO this should only show up for [CM, IM] */}
                        <div className="col-lg-12 mt-4 like-deslike-col">
                          <div className="like-deslike-btns">
                            {state?.seekerInterested == 2?
                                <a onClick={() => UpdateInterst(1)} className="like-btns">
                                  <i className="fa fa-thumbs-up" aria-hidden="true" />
                                  <span>Let's Connect</span>
                                </a>
                            : null}
                            {state?.posterInterested == 2?
                              <a onClick={() => UpdateInterst(0)} className="dislike-btns">
                                <i className="fa fa-thumbs-down" aria-hidden="true" />
                                <span>Not for Me</span>
                              </a>
                            : null}
                          </div>
                        </div>
                      </>
                    : 
                    <>{console.log(`${state?.posterInterested} - ${state?.seekerInterested}`)}</>
                    }
                    { state?.posterInterested === 2  && state?.seekerInterested === 2?
                      <>
                      {/* TODO this should only show up for [CM, IM] */}
                        <div className="col-lg-12 mt-4 like-deslike-col">
                          <div className="like-deslike-btns">
                            <a onClick={() => UpdateInterst(1)} className="like-btns">
                              <i className="fa fa-thumbs-up" aria-hidden="true" />
                              <span>Let's Connect</span>
                            </a>
                            <a onClick={() => UpdateInterst(0)} className="dislike-btns">
                              <i className="fa fa-thumbs-down" aria-hidden="true" />
                              <span>Not for Me</span>
                            </a>
                          </div>
                        </div>
                      </>
                    : 
                    <>{console.log(`${state?.posterInterested} - ${state?.seekerInterested}`)}</>
                    }
                    { state?.posterInterested === 1 !== state?.seekerInterested === 1 && state?.buyProfile === false && state?.isDislike === false ?
                      <>
                      {/* TODO this should only show up for [CM, IM] */}
                        <div className="col-lg-12 mt-4 like-deslike-col">
                          <div className="like-deslike-btns">
                            <a onClick={() => UpdateInterst(1)} className="like-btns">
                              <i className="fa fa-thumbs-up" aria-hidden="true" />
                              <span>Let's Connect</span>
                            </a>
                            <a onClick={() => UpdateInterst(0)} className="dislike-btns">
                              <i className="fa fa-thumbs-down" aria-hidden="true" />
                              <span>Not for Me</span>
                            </a>
                          </div>
                        </div>
                      </>
                    : null}
                    { state?.posterInterested === 1 && state?.seekerInterested === 1 ?
                      <>
                      {/* TODO this should only show up for [CM, IM] */}
                        {/* <div className="col-lg-12 mt-4 like-deslike-col">
                          <div className="like-deslike-btns">
                            <a onClick={() => UpdateInterst(0)} className="dislike-btns">
                              <i className="fa fa-thumbs-down" aria-hidden="true" />
                              <span>Not for Me</span>
                            </a>
                          </div>
                        </div> */}
                      </>
                    : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="dashboard-footer">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-xs-6 col-12 eagle-man-col">
                  <img src={EagleMan} alt="Eagle Man" />
                </div>
                <div className="col-lg-8 col-sm-12 col-xs-12 col-12 text-center">
                  <p>Copyright © 2022 perspectv.com. All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </footer>
        </>
      ) : (
        <h1 className="opacity-30">Loading...</h1>
      )}
    </div>
  );
}
