import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import GoBack from "../../../components/GoBack";
import brandLogo from "../../../assets/images/brand-logo.png";
import { postApiWithoutHeader, postApiPosterWithoutHeader } from "../../../utils";
import { getUserByEmail as getUserByEmailApi } from "../../../api/public/user";
import { API_POSTER_FORGOT_PASSWORD, API_SEEKER_FORGOT_PASSWORD } from "../../../utils";

const SendEmail = () => {
  const baseSubmitBtn = 'Submit'
  const [emailAddress, setEmailAddress] = useState("");
  const [isLoading, setLoading] = useState(false);
  const userType = useSelector((state) => state.userType.userType);
  const [submitBtnText, setSubmitBtnText] = useState(baseSubmitBtn)
  const navigate = useNavigate();

  const getOtpFromServer = async (e) => {
    e.preventDefault();
    if (!emailAddress) {
      toast.error("Please provide an email address!", {
        position: "top-right",
        autoClose: 5000
      });
      return;
    }

    setLoading(true);
    const payload = { email: emailAddress };
    try {
      setSubmitBtnText('Processing...')
      const apiRoute = userType.payload === "jobSeeker" ? API_SEEKER_FORGOT_PASSWORD : API_POSTER_FORGOT_PASSWORD;
      const response = await (userType.payload === "jobSeeker" ? postApiWithoutHeader : postApiPosterWithoutHeader)(apiRoute, payload, successCallBack, errorCallBack);
      toast.success('OTP Sent');
      navigate("../otpVerfication", { state: { obj: payload } });
    } catch (error) {
      console.log('error: ', error)
      // toast.error(error.response.data.message, {
      //   position: "top-right",
      //   autoClose: 5000
      // });
    } finally {
      setLoading(false);
      setSubmitBtnText(baseSubmitBtn)
    }
  };

  function successCallBack(_resp){
    console.log('success: ', _resp)
  }

  function errorCallBack(_resp){
    console.log('error: ', _resp)
  }

  // function seekerForgotPassword(){
  //   const payload = { email: emailAddress };
  //   postApiWithoutHeader(API_SEEKER_FORGOT_PASSWORD, payload).then((_resp) => {
  //     console.log(_resp)
  //   }).catch((_resp) => {
  //     console.error(_resp)
  //   })
  // }

  // function posterForgotPassword(){
  //   const payload = { email: emailAddress };
  //   postApiPosterWithoutHeader(API_POSTER_FORGOT_PASSWORD, payload).then((_resp) => {
  //     console.log(_resp)
  //   }).catch((_resp) => {
  //     console.error(_resp)
  //   })
  // }

  const handleCodeClick = async () => {
    if (!emailAddress) {
      toast.warning('Please provide an email address!', {
        position: 'top-right',
        autoClose: 5000,
      });
      return;
    }
    try {
      const response = await getUserByEmailApi(emailAddress);
      navigate("../otpVerfication", { state: { obj: { email: emailAddress, _id: response.data.user._id } } });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <section id="login-page">
      <div className="container">
        <div className="row justify-content-center">
          <GoBack />
          <div className="col-lg-6 text-center">
            <div className="brand-logo">
              <img className="img-fluid" src={brandLogo} alt="brand logo" />
            </div>
            <form onSubmit={getOtpFromServer}>
              <input
                className="form-control"
                type="email"
                name="email"
                onChange={(e) => setEmailAddress(e.target.value)}
                placeholder="Please enter your email address"
              />
              <h6>
                Enter the email address associated with your account. We’ll send you an email with a magic code that’ll allow you to reset your password.
              </h6>
              {isLoading ? (
                <button className="loading-btn" type="button" disabled>
                  <span className="spinner-grow spinner-grow-lg mx-2" role="status" aria-hidden="true"></span>
                  Processing..
                </button>
              ) : (
                <button className="form-btn" type="submit">{submitBtnText}</button>
              )}
            </form>
            <p className="login-signup-txt">
              Don’t have an account? <strong onClick={() => navigate("/signup")}>Sign up</strong>
            </p>
            <p className="login-signup-txt">
              <strong onClick={handleCodeClick}>Already Have A Reset Code?</strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SendEmail;