import React, { useEffect, useState } from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {API_GET_POINT_INFO_DATA, getApi, postApi} from '../../../../utils';
import Swal from 'sweetalert2';
import {useSelector} from 'react-redux';
import {API_UPDATE_INTEREST, API_PERSONAL_BUYPROFILE} from '../../../../utils';
import moment from 'moment';
import {toast} from 'react-toastify';
import GoBack from "../../../../components/GoBack";
import { getPercentageImage, getPercentageData } from '../../../../utils/matches-details'

export default function ViewProfile() {
const userType = useSelector(state => state.userType.userType);
const userToken = useSelector(state => state.UserAuth.userData.payload.token);
const {state} = useLocation();
const [loading, setLoading] = useState(true);
const [data, setData] = useState(null);
// console.log("data", data)

useEffect(() => {
    loadData();
}, []);

const loadData = () => {
    const axiosConfig = { headers: { Authorization: "Bearer " + userToken }};
    getApi(API_GET_POINT_INFO_DATA + "/" + state.job._id, axiosConfig, loadDataSuccess, loadDataError);
};

const loadDataSuccess = (result) => {
    setLoading(false);
    setData(result.data.data[0]);
};

const loadDataError = () => {
    console.log("An error occurred");
};

const navigation = useNavigate();

const percent = data?.percentage.toFixed(0);

const personalInfo = data?.personalInfo[0];

const agentName = personalInfo?.fullName;
const companyDes =
    personalInfo?.companyDesc === null
        ? 'A business description is most relevant when starting a company but is often effective when maintained regularly as a business grows.'
        : personalInfo?.companyDesc;
const image = personalInfo?.imagePath || personalInfo?.image;
const video = personalInfo?.videoPath;
const personalbio = data?.personalbio;
const info = data?.info;
var jobLocation = `${info?.locationName ?? ''}`;

const companySize = info?.campanySize;
const jobDes =
    info?.jobDesc === null
        ? 'Informative job titles are more effective than general ones, so be precise by including key phrases that accurately describe the role.'
        : info?.jobDesc;
const infoCompanyDesc =
    info?.companyDesc === null
        ? 'A business description is most relevant when starting a company but is often effective when maintained regularly as a business grows.'
        : info?.companyDesc;
const bio = personalInfo?.bio;
const salary = info?.employmentMode;
const startDate = info?.startDate;
const jobTitle = info?.jobTitle?.option;
const companyName = personalInfo?.companyName ? personalInfo?.companyName : jobTitle;
// const soSkill = info?.softSkill?.map(item => {
//     return ' ' + item.skill.option + ' (' + `${item.skill.exp}` + ')';
// });
// const hdSkill = info?.hardSkill?.map(item => {
//     return ' ' + item.skill.option + ' (' + `${item.skill.exp}` + ')';
// });

// const softSkill = soSkill?.toString();
// const hardSkill = hdSkill?.toString();

const status = state.selectedProfile;

const [isMobile, setIsMobile] = useState(false);

useEffect(() => {
    if (window.matchMedia('(max-width: 767px)').matches) {
        setIsMobile(true);
    } else {
        setIsMobile(false);
    }
}, [window]);

//Save thubm action in databse
const UpdateInterst = value => {
    if (userType.payload === 'jobSeeker') {
        const obj = {
            type: 1,
            seekerId: data?.seekerId,
            posterId: data?.posterId,
            jobId: data?.jobId,
            status: value,
        };
        if (value === 1) {
            postApi(API_UPDATE_INTEREST, obj, userToken, successUpdateInterestFunction, errorFunction);
        } else {
            navigation('/homepage/feedback', {state: obj});
        }
    } else if (userType.payload === 'jobPoster') {
        const obj = {
            type: 2,
            seekerId: data?.seekerId,
            posterId: data?.posterId,
            jobId: data?.jobId,
            status: value,
        };
        if (value === 1) {
            postApi(API_UPDATE_INTEREST, obj, userToken, successUpdateInterestFunction, errorFunction);
        } else {
            navigation('/homepage/feedback', {state: obj});
        }
    }
};

// function onUpdateInterestClick(_like, _job){
//     console.log('state: ', state)
//     console.log('seeker: ', _job)
//     const obj = {
//         type: 1 ? userType.payload === 'jobSeeker': 0,
//         seekerId: _job['seekerId'],
//         posterId: _job['posterId'],
//         jobId: _job['jobId'],
//         status: _like,
//     };

//     if(_like !== 1){
//         navigate('/homepage/feedback', {state: obj});
//     }

//     updateInterestApi(obj).then((_resp) => {
//         navigate(-1);
//     }).catch((_resp) => {
//         console.error(_resp)
//     }).finally(() => {

//     })
// }

const successUpdateInterestFunction = () => {
    navigation(-1);
};

const BuyProfile = () => {
    const obj = {
        seekerId: data?.seekerId,
        posterId: data?.posterId,
        jobId: data?.jobId,
        buyProfile: 1,
    };
    Swal.fire({
        title: 'To purchase this profile. You will charged 1 credit from your credit wallet',
        //text: "You don`t have credit purchase to this profile",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
    }).then(result => {
        if (result.isConfirmed) {
            postApi(API_PERSONAL_BUYPROFILE, obj, userToken, successBuyProfileFunction, errorFunction);
        }
    });
};

const successBuyProfileFunction = () => {
    navigation(0); // refresh
};

const errorFunction = err => {
    toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    navigation("/paymentOption");
};

const handleChat = () => {
    navigation('/chat', {state: data});
};

const styles = {
    btnStyle: {
        transform: 'scale(0.8)',
        marginTop: '-1%',
    },
};

const [openVideo, setOpenVideo] = useState(false);
// const circleColour = data?.percentage >= 60 ? "#1ec991" : data?.percentage >= 50 ? "#5e43f8" : "#d9453d";

// const matchData = data?.matchData;

// let hardSkillsData = null;
// let hardSkillsPercentage = null;
// let hardSkillsPercentageColor = null;

// let softSkillsData = null;
// let softSkillsPercentage = null;
// let softSkillsPercentageColor = null;

// if (matchData) {
//     hardSkillsData = matchData.hardSkillsResult;
//     hardSkillsPercentage = hardSkillsData.finalMatchPercentage.toFixed(0);
//     hardSkillsPercentageColor = hardSkillsPercentage >= 60 ? "#1ec991" : hardSkillsPercentage >= 40 ? "#ffdb59" : "#d9453d";

//     softSkillsData = matchData.softSkillsResult;
//     softSkillsPercentage = softSkillsData.finalMatchPercentage.toFixed(0);
//     softSkillsPercentageColor = softSkillsPercentage >= 60 ? "#1ec991" : softSkillsPercentage >= 40 ? "#ffdb59" : "#d9453d";
// }

// const getSkillProgress = (skill) => {
//     if (skill.qualify) {
//         const matchPercentage = ((skill.matchTotal) * 100).toFixed(0);
//         const color = matchPercentage >= 60 ? "#1ec991" : matchPercentage >= 40 ? "#ffdb59" : "#d9453d";
//         return (
//             <React.Fragment>
//                 <div style={{width: '100%', height: '20px', border: '1px solid #b8b8b8'}}>
//                     <div style={{width: matchPercentage + "%", height: '18px', background: color}}></div>
//                 </div>
//             </React.Fragment>
//         );
//     } else {
//         return null;
//     }
// };

const createMatchCardComp = (percent) => {
    const config = getPercentageImage(percent);
    return (
        <div className="d-flex justify-content-between">
            <div className="text-end text-nowrap">
                {/* <br /> */}
                <h5 className='text-end mt-3'>{config.name}</h5>
                <div className='text-center'>
                    <b className='float-end p-1' style={{ backgroundColor: config.color }}>{config.text}</b>
                </div>
            </div>
            <div>
                <img src={`/progress-images/${config.image}.png`} className="img-fluid match-card-image" style={{ borderRadius: '15px', height: '130px', width: '130px' }} />
            </div>
        </div>
    );
};

const createMatchDescription = (_percent) => {
    const config = getPercentageData(percent);
    return <>
        <div className='d-flex'>
            <img src={`/progress-images/${config.image}.png`} className="img-fluid match-desc-image" style={{ borderRadius: '15px', height: '200px', width: '200px' }} />
            <div className="">
                <div className='text-center mt-4'>
                    <b className='float-start p-1' style={{ backgroundColor: config.title_color }}>{config.title}</b>
                    <br />
                    <br />
                    <p className='text-start'>
                        <b>{config['context']}</b> 
                        <br />
                        {config['description']}
                    </p>
                </div>
            </div>
        </div>
    </>
}

const ProfileActions = () => {
    const { posterInterested, seekerInterested, info, buyProfile, personalInfo } = data || {};

    const isInterested = posterInterested === 1 && seekerInterested === 1;
    const isUserTypeSeeker = info?.userType === 1;
    const isUserTypeCompany = info?.userType === 2;

    const renderProfileDetails = (label, value) => (
        <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
            <h5>{label}</h5>
            <p>{value}</p>
        </div>
    );

    const renderButtons = () => (
        <div className="col-lg-12 mt-4 like-deslike-col">
            <div className="like-deslike-btns">
                <a onClick={() => UpdateInterst(1)} className="like-btns">
                    <i className="fa fa-thumbs-up" aria-hidden="true" />
                    <span>Let's Connect</span>
                </a>
                <a className="dislike-btns" onClick={() => UpdateInterst(0)}>
                    <i className="fa fa-thumbs-down" aria-hidden="true" />
                    <span>Not for Me</span>
                </a>
            </div>
        </div>
    );

    return (
        <>
            {isInterested && isUserTypeSeeker && !buyProfile && (
                <div className="col-sm-12 mt-4 like-deslike-col">
                    <button onClick={BuyProfile} className="matching-button" style={{ background: '#1ec991', width: '300px', fontSize: '20px',...styles?.btnStyle}}>
                        Add to Talent List
                    </button>
                </div>
            )}

            {isInterested && isUserTypeSeeker && buyProfile && (
                <>
                    {renderProfileDetails('Seeker Name', personalInfo?.[0]?.fullName)}
                    {renderProfileDetails('Email', personalInfo?.[0]?.email)}
                    {renderProfileDetails('Contact Number', personalInfo?.[0]?.contactNumber)}

                    <div>
                        <a href={personalInfo?.[0]?.document} className="perspectv-button" style={{ background: '#f45950', fontSize: '18px' }} download>
                            Download CV
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <button onClick={handleChat} className="perspectv-button" style={{ background: '#1ec991', fontSize: '18px' }}>
                            Message
                        </button>
                    </div>
                </>
            )}

            {isInterested && isUserTypeCompany && buyProfile && (
                <>
                    {renderProfileDetails('Company Name', personalInfo?.[0]?.companyName)}
                    {renderProfileDetails('Contact Number', personalInfo?.[0]?.contactNumber)}

                    <div className={`message-btn text-right ${isMobile ? 'd-flex justify-content-center' : 'd-flex justify-content-end'}`}>
                        <button onClick={handleChat} className="matching-button"
                            style={{ background: '#1ec991', fontSize: '18px', width: '200px', ...styles?.btnStyle }}>
                            Message
                        </button>
                    </div>
                </>
            )}

            {!isInterested && renderButtons()}
        </>
    );
};

return <>
{loading ? (
        <div className='d-flex justify-content-center align-items-center LoaderCenter'>
            {' '}
            <div className='block'>
                <span className='spinner-grow spinner-grow-lg mx-2' role='status' aria-hidden='true'/>
                <h6 className='LoaderText'>Processing...</h6>
            </div>
        </div>
    ) : (
        <div>
            <GoBack/>
            <div className="p-4">
                <div className="row d-flex justify-content-center">
                    <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12 col-xs-12 mircroshoft-cnt">
                        <div className="row">
                            <div className="col-lg-12 mt-4">
                                <p style={{fontFamily: '\'Space Mono\', monospace', fontSize: '1.4em'}}>
                                    <span style={{color: '#1ec991'}}>Suggested for</span>&nbsp;<b className="capitalize"> {data?.postsJobFor[0]?.jobTitle?.option} &ndash; {data?.postsJobFor[0].locationName}</b>{' '}
                                </p>
                            </div>

                            <div className={"col-lg-12"}>
                                <div className="match-card">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex justify-content-between">
                                            <div className="match-card-image">
                                                <div className={'match-card-image-container'}>
                                                    <img src={image} alt="Profile picture"/>
                                                    {video && (
                                                        <div className={'video-profile'} onClick={() => setOpenVideo(!openVideo)}>
                                                            <i className="fa fa-play text-light" style={{paddingLeft: '2px',}}/>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="match-card-info">
                                                <div className="match-name">{state?.selectedProfile === 'Short Listed' ? agentName : agentName?.split(" ")?.[0]}&nbsp;&ndash;&nbsp;<span className="capitalize">{companyName}</span></div>
                                                <div>{jobLocation}</div>
                                            </div>
                                        </div>
                                        <div className="match-card-percent">
                                            {createMatchCardComp(percent)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-lg-12"}>
                                <p className={"mt-3"} style={{fontSize: "1.2em", fontWeight: "bold"}}><span style={{color: "#1ec991"}}>Status</span>&nbsp;&nbsp;{status}</p>
                            </div>

                            <div className="">
                                <h5>Understanding Candidate Matches</h5>
                                <p>
                                    We categorize candidates into different tiers based on their match percentage. Each tier comes with a representative image
                                    and description to guide you through the selection process.
                                </p>
                                {createMatchDescription(percent)}
                            </div>

                            {userType.payload === 'jobPoster' ? (
                                <div className="col-lg-12 mt-4">
                                    <h5>Elevator Pitch</h5>
                                    <p>{personalbio}</p>
                                </div>
                            ) : (
                                <div className="col-lg-12 mt-4">
                                    <h5>Company Pitch</h5>
                                    <p className="capitalize">{companyDes || bio || infoCompanyDesc}</p>
                                </div>
                            )}

                            {userType.payload === 'jobPoster' ? (
                                <div></div>
                            ) : (
                                <div className="col-lg-12 mt-4">
                                    <h5>Job description</h5>
                                    <p className="capitalize">{jobDes}</p>
                                </div>
                            )}

                            {userType.payload === 'jobPoster' ? (
                                <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                                    <h5>Desired Job Role</h5>
                                    <p className="capitalize"> {data?.info?.role?.option} </p>
                                </div>
                            ) : (
                                <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                                    <h5>Expected Company</h5>
                                    <p className="capitalize">{companySize}</p>
                                </div>
                            )}

                            {userType.payload === 'jobPoster' ? (
                                <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                                    <h5>Desired Job Title</h5>
                                    <p className="capitalize">{data?.info?.jobTitle?.option}</p>
                                </div>
                            ) : (
                                <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                                    <h5>Maximum Salary</h5>
                                    <p className="capitalize">{salary}</p>
                                </div>
                            )}

                            {userType.payload === 'jobPoster' ? (
                                <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                                    <h5>Desired Industry</h5>
                                    <p className="capitalize">{data?.info?.industry?.option}</p>
                                </div>
                            ) : (
                                <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                                    <h5>Start date</h5>
                                    <p className="capitalize">{moment(startDate).format('YYYY/MM/DD')}</p>
                                </div>
                            )}

                            {userType.payload === 'jobPoster' ? (
                                <div className="col-lg-3 col-md-4 col-xs-6 mt-4">
                                    <h5>Education Levels</h5>
                                    <p className="capitalize">{data?.info?.educationLevel?.option}</p>
                                </div>
                            ) : null}

                            <div className="col-lg-6">
                                <div className="skills-info-card">
                                    <div className="skills">
                                        <p style={{ fontWeight: 'bold' }}>Job Specific Skills</p>
                                        <div className="skills-container container">
                                            <ul>
                                                {data.info.hardSkill.map(obj => 
                                                    <li className='text-start' key={obj['id']}>{obj['skill']['option']} - {obj['skill']['explevel']} - {obj['skill']['exp']}</li>
                                                )}
                                            </ul>
                                            {/* {hardSkillsData?.skills.map((skill) => (
                                            <div className="row" key={skill.name}>
                                                <div className="col-md-4">
                                                    <div className="skill-name">{skill.name}</div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="skill-progress">{getSkillProgress(skill)}</div>
                                                </div>
                                            </div>
                                            ))} */}
                                        </div>
                                    </div>
                                    <div className="match-percentage">
                                        <p style={{ fontWeight: 'bold' }}>Match</p>
                                        {/* <div className="match-card-percent-container" style={{ background: hardSkillsPercentageColor }}>
                                            {hardSkillsPercentage}%
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="skills-info-card">
                                    <div className="skills">
                                        <p style={{ fontWeight: 'bold' }}>People Skills</p>
                                        <div className="skills-container container">
                                            <ul>
                                                {data.info.softSkill.map(obj => 
                                                    <li className='text-start' key={obj['id']}>{obj['skill']['option']} - {obj['skill']['explevel']} - {obj['skill']['exp']}</li>
                                                )}
                                            </ul>
                                            {/* {softSkillsData?.skills.map((skill) => (
                                            <div className="row" key={skill.name}>
                                                <div className="col-md-4">
                                                    <div className="skill-name">{skill.name}</div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="skill-progress">{getSkillProgress(skill)}</div>
                                                </div>
                                            </div>
                                            ))} */}
                                        </div>
                                    </div>
                                    <div className="match-percentage">
                                        <p style={{ fontWeight: 'bold' }}>Match</p>
                                        {/* <div className="match-card-percent-container" style={{ background: softSkillsPercentageColor }}>
                                            {softSkillsPercentage}%
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {ProfileActions()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
</>
}